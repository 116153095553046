<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm">
      <h2 class="ticket-title">Добавить жителя</h2>
      <div class="close" @click="closeModal">&#10005;</div>
      <div class="add-resident">
        <div class="address-row">
          <div class="address">
            <div class="type">Блок</div>
            <div class="num">{{ apt.block.number }}</div>
          </div>
          <div class="address">
            <div class="type">Подъезд</div>
            <div class="num">{{ apt.entrance }}</div>
          </div>
          <div class="address">
            <div class="type">Этаж</div>
            <div class="num">{{ apt.floor }}</div>
          </div>
          <div class="address">
            <div class="type">Квартира</div>
            <div class="num">{{ apt.number }}</div>
          </div>
        </div>

        <v-form class="add-resident" ref="newResident">
          <v-row no-gutters>
            <v-col class="mb-2" cols="12">
              <v-text-field
                v-model="formData.first_name"
                :rules="newResidentRules"
                label="Имя"
                color="#5b6cff"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="mb-2" cols="12">
              <v-text-field
                v-model="formData.last_name"
                :rules="newResidentRules"
                label="Фамилия"
                color="#5b6cff"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="mb-2" cols="12">
              <v-text-field
                v-model="formData.email"
                :rules="emailRules"
                label="E-mail"
                type="email"
                color="#5b6cff"
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-2" cols="12">
              <v-text-field
                v-model="formData.phone"
                :rules="phoneRules"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="+7 (___) ___-__-__"
                label="Телефон"
                color="#5b6cff"
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="mb-2" cols="12" md="7">
              <v-select
                v-model="formData.provider"
                :rules="newResidentRules"
                :items="providersList"
                item-text="title"
                item-value="id"
                label="Тип лицевого счёта"
                :menu-props="{ bottom: true, offsetY: true }"
                color="#5b6cff"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col class="mb-2" cols="12" md="5">
              <v-text-field
                v-model="formData.personal_account"
                :rules="accountRules"
                v-mask="'#########'"
                label="Лицевой счёт"
                type="number"
                color="#5b6cff"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-5">
            <v-btn
              color="#ff7171"
              @click="toggleDeleteFlat"
            >
              Удалить квартиру
            </v-btn>
            <v-btn
              color="#5b6cff"
              @click="submitNewResident"
              :disabled="loading === true"
            >
              <div v-show="loading === true">
                <v-progress-circular
                  indeterminate
                  color="#5b6cff"
                  size="30"
                  width="3"
                ></v-progress-circular>
              </div>
              <div v-show="loading === false">Добавить жителя</div>
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </div>
    <DeletionConfirm v-if="showDeleteFlat"
      title="Вы действительно хотите удалить эту квартиру?"
      :description="`${apt.entrance} подъезд ${apt.floor} этаж ${apt.number} квартира`"
      @close="toggleDeleteFlat"
      @delete="deleteFlat"
    />
  </div>
</template>
<script>
import {addBuildingOwner,addPersonalAccount} from '@/api/buildings';
import DeletionConfirm from "./DeletionConfirm"

export default {
  components: {DeletionConfirm},
  props: {
    apt: {
      type: Object,
      default: () => {},
    },
    providersList: {
      type: Array,
      default: () => {},
    },
  },
  mounted() {
    this.preview = require("@/assets/images/camera.svg");
  },
  data: () => ({
    loading: false,
    showDeleteFlat: false,
    preview:'',
    formData: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      personal_account: '',
      provider: 0,
    },
    newResidentRules: [(v) => !!v || "Обязательное поле"],
    phoneRules: [
      v => (!v || v.length >= 18) || 'Неправильный формат номера',
    ],
    emailRules: [
      v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || !v || 'Неправильный формат E-mail'
        },
      ],
    accountRules: [
      v => !!v || 'Обязательное поле',
      v => (!v || v.length >= 6) || 'От 6 до 9 символов'
    ],
  }),
  methods: {
    submitNewResident() {
      if (this.$refs.newResident.validate() === true) {
        this.loading = true;
        this.addResident();
        this.$emit('created');
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'addResidentErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', "Заполните форму правильно!");
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      };
    },
    closeModal() {
      this.$emit("close");
    },
    toggleDeleteFlat() {
      this.showDeleteFlat = !this.showDeleteFlat;
    },
    async deleteFlat(){
      let id = this.apt.apartment_id;
      this.$emit('delete',id);
      this.$emit('close');
    },
    async addResident() {
      try {
        let personal_account_payload = {
          building: this.apt.apartment_id,
          personal_account: this.formData.personal_account,
          provider: this.formData.provider,
          last_name: this.formData.last_name,
          first_name: this.formData.first_name,
          address: "Блок: " +this.apt.block.number+" Подъезд: "+this.apt.entrance+
              " Этаж: "+this.apt.floor+" Квартира "+this.apt.number,
        };
        await addPersonalAccount(personal_account_payload);

        if (this.formData.phone){
          let owner_payload = {
            building: this.apt.apartment_id,
            phone: this.formData.phone.replace(/[^0-9.]/g, ""),
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            email: this.formData.email
          };
          await addBuildingOwner(owner_payload);
        };
        this.$emit('created');
        this.$emit("close");
        this.$store.commit('SHOW_SNACKBAR', 'addResidentSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Житель успешно добавлен');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch(error) {
        let errorMessage = error.response.data.message;
        this.$store.commit('SHOW_SNACKBAR', 'addResidentErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      } finally {
        this.loading = false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.col-md-7 {
  padding-right: 4px !important;
}
.col-md-5 {
  padding-left: 4px !important;
}
.v-card__actions {
  padding: 0;
}
.v-card__actions .v-btn {
  width: calc((100% - 8px) / 2);
}
</style>
